<script>
import { getValue, EVALUATE_TYPE, DIALOG_TITLE_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { createTag, getTagList } from "@/api/store";

export default {
  computed: {
    formatApiDate() {
      return formatApiDate;
    },
    EVALUATE_TYPE() {
      return EVALUATE_TYPE;
    },
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        evaluate: "" // 评价
      },
      form: {
        id: 0,
        evaluate: "", // 评价
        tag: "" // 标签
      },
      rules: {
        evaluate: [
          { required: true, message: "评价不能为空", trigger: "change" }
        ],
        tag: [{ required: true, message: "标签不能为空", trigger: "change" }]
      },
      dialogStatus: "CREATE",
      dialogFormVisible: false,
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getTagList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    checkDialogFormVisible(status, obj = {}) {
      this.dialogFormVisible = status;
      if (status) {
        this.form = {
          id: obj.id,
          evaluate: obj.evaluate, // 评价
          tag: obj.tag // 标签
        };
      } else {
        this.form = {
          id: 0,
          evaluate: null, // 评价
          tag: null // 标签
        };
      }
    },
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };
          this.btnLoading = true;
          createTag(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.evaluate"
        placeholder="评价"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in EVALUATE_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">标签数量：{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column label="评价" align="center" prop="evaluate">
        <template slot-scope="scope">
          <span>{{ getValue(EVALUATE_TYPE, scope.row.evaluate) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="评价内容"
        align="center"
        prop="tag"
      ></el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="180"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="评价" prop="evaluate">
          <el-select
            v-model="form.evaluate"
            placeholder="请选择评价"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in EVALUATE_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="标签" prop="tag">
          <el-input v-model="form.tag" placeholder="请输入标签" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss"></style>
